﻿.home-intro {
	position: relative;
	z-index: 1;
	padding: rem(103px) 0 rem(62px);

	.container {
		margin: 0 auto;
	}

	.title {
		text-align: center;
		margin: 0 0 rem(55px);
	}

	.intro-image {
		float: right;
		width: 100%;
		height: auto;
		margin: 0 0 rem(32px);

		@include breakpoint("mobile-l") {
			max-width: rem(258px);
			margin: 0;
		}
	}

	.content {
		@include font-size-14;
	}

	.background-image {
		position: absolute;
		z-index: -1;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;
	}

	.column {
		margin-bottom: rem(48px);
	}

	.dropdown-list {
		height: 100%;
		margin: 0;

		dt {
			@include font-size-30;
			font-weight: 800;
			text-transform: uppercase;
			padding: rem(35px) rem(94px) rem(36px) rem(40px);
			clip-path: polygon(0 0, 100% 0, 100% 100%, rem(24px) 100%, 0% calc(100% - 1.5rem)); // 24px
			background: #fff;
			height: 100%;
			color: $color-primary-light;

			strong {
				color: $color-primary;

				&:after {
					@include icon($icon-slash);
					position: relative;
					font-size: rem(21px);
					top: rem(-6px);
					left: rem(3px);
					color: $color-primary-light;

					@include breakpoint("tablet") {
						left: rem(13px);
					}
				}
			}

			&::after {
				@include icon($icon-down);
				position: absolute;
				width: rem(30px);
				height: rem(30px);
				padding: rem(10px) 0 0 rem(1px);
				border-radius: 50%;
				top: 50%;
				right: rem(45px);
				transform: translateY(-50%);
				background: #ccd2db;
				font-size: rem(13px);
				text-align: center;
				color: #fff;
			}
		}

		dd {
			box-sizing: border-box;
			background: #fff;
			margin: 0;
			clip-path: polygon(0 0, 100% 0, 100% 100%, rem(24px) 100%, 0% calc(100% - 1.5rem)); // 24px
			padding: 0 rem(40px) rem(40px) rem(40px);
		}

		&:not(.clicked):focus-within,
		&.clicked.open {
			z-index: 2;

			dt,
			dt:focus {
				clip-path: initial;

				&::after {
					content: $icon-up;
					padding: rem(8px) 0 0 rem(1px);
				}
			}
		}
	}
}
