﻿.portal {
	padding: 0;

	header {
		height: rem(100px);
		text-align: center;

		.logo {
			margin-top: rem(15px);
		}
	}

	main {
		display: flex;
		min-height: calc(100vh - 6.25rem); // 100px
		align-items: center;

		.js & {
			min-height: calc(var(--hh) - 6.25rem); // 100px
		}
	}

	.portal__content {
		width: 100%;
		max-width: rem(672px);
		padding: rem(43px) rem(20px) rem(50px);
		margin: 0 auto;
	}

	.portal-title {
		@include font-size-40;
		text-transform: uppercase;
		text-align: center;
		font-weight: 800;

		+ .portal__languages {
			margin: rem(35px) 0 0;
		}
	}

	.portal__languages {
		@include font-size-20;
		overflow: hidden;
		list-style: none;
		padding: 0;
		font-weight: 800;
		clip-path: polygon(0 0, 100% 0, 100% 100%, rem(24px) 100%, 0% calc(100% - 1.5rem)); // 24px
	}

	.portal__languages-item {

		&:last-child .portal__languages-link {
			border-bottom: none;
		}
	}

	.portal__languages-link {
		position: relative;
		z-index: 0;
		display: block;
		text-transform: uppercase;
		padding: rem(29px) rem(100px) rem(29px) rem(40px);
		background: #fff;
		border-bottom: 1px rgba($color-primary-light, 0.2) solid;
		transition: background $transition-time ease-in-out;
		overflow: hidden;

		&:before {
			background: #fff;
			content: "";
			height: rem(155px);
			left: rem(-100px);
			opacity: .2;
			position: absolute;
			top: rem(-50px);
			transform: rotate(-35deg);
			transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
			width: rem(50px);
			z-index: -10;
			background: rgba($color-primary-light, 0.4);
		}

		&:after {
			@include icon($icon-right);
			position: absolute;
			width: rem(20px);
			height: rem(20px);
			padding: rem(6px) 0 0 rem(2px);
			top: rem(30px);
			right: rem(40px);
			border-radius: 50%;
			background: $color-primary-light;
			font-size: rem(8px);
			text-align: center;
			color: #fff;
		}

		&:focus {
			background: mix($color-primary-light, #fff, 20%);

			&:before {
				left: 120%;
				transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
			}
		}

		&:hover {
			@media (hover: hover) {
				background: mix($color-primary-light, #fff, 20%);

				&:before {
					left: 120%;
					transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
				}
			}
		}
	}

	.portal__background-image {
		position: absolute;
		z-index: -1;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;
	}
}
