﻿#nav-toggler {
	display: none;

	&:checked + .nav-toggler {
		@extend .is-active;
	}

	&:checked ~ .nav.is-side-nav {
		opacity: 1;
		right: 0;

		&:before {
			pointer-events: initial;
		}
	}
}

.nav.is-side-nav {
	position: fixed;
	z-index: 1000;
	max-width: rem(476px);
	width: 100%;
	height: 100vh;
	padding: rem(113px) 0 rem(80px);
	top: 0;
	right: rem(-100%);
	//right: 0;
	background: $color-primary;
	color: #fff;
	opacity: 0;
	//opacity: 1;
	transition: all $transition-time ease-in-out;
	font-weight: 600;

	.nav-container {
		overflow-y: auto;
		height: 100%;
		list-style: none;
		padding: 0;
	}

	.navbar-container {
	}

	li {
		position: relative;
		padding: 0;

		&:last-child a {
			border-bottom: 1px solid rgba(#fff, 0.2);
		}

		input {
			display: none;

			&:checked ~ label i:before {
				@include icon($icon-arrow-up);
			}

			&:checked ~ ul {
				display: block;
			}
		}

		label {
			position: absolute;
			z-index: 2;
			width: rem(60px);
			height: rem(59px);
			top: 0;
			right: 0;
			border-top: 1px solid rgba(#fff, 0.2);
			border-left: 1px solid rgba(#fff, 0.2);
			cursor: pointer;
			background: $color-primary;

			i:before {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			&:hover {
				background: lighten($color-primary, 15%);
			}
		}

		ul {
			@include font-size-16;
			background: $color-primary-hover;
			padding: 0;
			list-style: none;
			display: none;

			a {
				padding: rem(15px) rem(80px) rem(17px);

				&[href^="https://webshop.weterings"] {
					color: $color-webshop;

					span {
						position: relative;
						padding-left: rem(40px);
					}

					span:after {
						@include icon($icon-shopping);
						position: absolute;
						font-size: rem(24px);
						top: 50%;
						left: 0;
						transform: translateY(-50%);
					}
				}
			}
		}
	}

	a {
		display: block;
		width: 100%;
		padding: rem(13px) rem(60px) rem(15px);
		border-top: 1px solid rgba(#fff, 0.2);
		transition: all $transition-time ease-in-out;

		&:focus {
			background: lighten($color-primary, 10%);
		}

		&.is-active {
			background: lighten($color-primary, 15%);
		}

		&:hover {
			background: lighten($color-primary, 15%);
		}
	}


	&:before {
		position: fixed;
		z-index: -1;
		content: '';
		top: 0;
		right: 0;
		width: 100vw;
		height: 100vh;
		background: rgba(#000, 0.25);
		pointer-events: none;
	}

	.languages {
		@extend .languages-side-menu;
		z-index: 2;
	}
}
