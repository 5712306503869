﻿.content-block {
	position: relative;
	overflow: hidden;
	padding: rem(79px) 0 rem(51px);

	@include breakpoint("tablet") {
		padding: rem(109px) 0 rem(81px);
	}

	&.is-intro {
		padding: rem(77px) 0 rem(51px);

		@include breakpoint("tablet") {
			padding: rem(107px) 0 rem(81px);
		}

		.breadcrumb {
			margin: rem(-57px) 0 rem(51px);

			@include breakpoint("tablet") {
				margin: rem(-81px) 0 rem(81px);
			}
		}
	}

	&.is-slider-block {
		padding: rem(77px) 0 rem(30px);

		@include breakpoint("tablet") {
			padding: rem(107px) 0 rem(60px);
		}

		&.has-grey-background {
			padding: rem(77px) 0 rem(90px);

			@include breakpoint("tablet") {
				padding: rem(107px) 0 rem(120px);
			}
		}

		.swiper-button-next,
		.swiper-button-prev {
			margin: -44px 0 0;
		}

		.buttons:last-child {
			margin-bottom: rem(-28px);
		}

		.content {
			text-align: center;
		}
	}

	&.has-white-background:not(.has-video) + .has-white-background {

		&:before {
			position: absolute;
			content: '';
			width: 100%;
			height: 1px;
			max-width: rem(1152px);
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			background: rgba($color-primary-light, 0.2);
		}
	}

	&.has-grey-background {
		background: $color-background;
	}

	&.is-big-image-block {
		padding: 0;

		.columns {
			min-height: rem(960px);
		}

		.content-block-big-image {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}

		.dont-stretch-image .content-block-big-image {
			object-fit: contain;
		}

		.content-container {
			width: 100%;
			max-width: rem(520px);
			padding: rem(79px) rem(20px) rem(51px);

			@include breakpoint("tablet") {
				padding: rem(109px) rem(20px) rem(81px);
			}

			.home & {
				max-width: rem(600px);
			}

			.button:last-child {
				margin-bottom: rem(39px);
			}
		}

		.content-wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	&.is-speaker-block,
	&.is-highlighted-slider-block {
		background: $color-background url(../../images/patterns/pattern-grey.svg) no-repeat right top;
		background-size: rem(1280px) rem(2880px);

		.button-container {
			display: flex;
			justify-content: center;
		}
	}

	&.is-form-block {
		background: $color-primary-light url(../../images/patterns/pattern-blue.svg) no-repeat right top;
		background-size: rem(1280px) rem(2880px);
		color: #fff;

		.container {
			position: relative;

			@include breakpoint("laptop-l") {
				min-height: rem(640px);
			}
		}

		.form-container {

			@include breakpoint("laptop-l") {
				max-width: rem(792px);
			}
		}

		.column {
			margin: 0 0 rem(16px);
		}

		.title {

			&:after {
				@include icon($icon-slash);
				position: relative;
				font-size: rem(20px);
				top: rem(-6px);
				left: rem(12px);

				@include breakpoint("tablet") {
					font-size: rem(28px);
					top: rem(-9px);
					left: rem(26px);
				}
			}
		}

		label {
			display: block;
			margin: 0 0 rem(1px);
		}

		input {
			margin: 0 0 rem(11px);
		}

		.form-button-container {
			display: flex;
			justify-content: flex-end;
		}

		.image {
			display: none;
			position: absolute;
			right: rem(-244px);
			bottom: rem(-129px);

			@include breakpoint("laptop-l") {
				display: block;
			}
		}
	}

	&.is-album-block {
		text-align: center;

		.image-title {
			display: block;
			margin: 0 0 rem(6px);
		}

		.columns {
			display: flex;
			justify-content: center;
		}
	}

	&.is-brands {
		padding: rem(88px) 0 0;

		@include breakpoint("tablet") {
			padding: rem(58px) 0 0;
		}

		.column {
			margin: 0 0 rem(63px)
		}

		.image {
			padding: rem(10px);
		}
	}

	&.is-columns-block {
		padding-bottom: rem(11px);

		@include breakpoint("tablet") {
			padding-bottom: rem(41px);
		}

		.column {
			position: relative;
			margin: 0 0 rem(40px);

			.image {
				width: 100%;
				margin: 0 0 rem(38px);
			}
		}
	}

	&.is-project-overview {
		padding: rem(60px) 0 rem(120px);

		@include breakpoint("tablet") {
			padding: rem(30px) 0 rem(90px);
		}
	}

	&.is-product-intro {
		padding: rem(77px) 0 rem(51px);

		@include breakpoint("tablet") {
			padding: rem(107px) 0 rem(81px);
		}

		.breadcrumb {
			margin: rem(-57px) 0 rem(51px);

			@include breakpoint("tablet") {
				margin: rem(-81px) 0 rem(81px);
			}
		}

		&.has-video {

			&:after {
				position: absolute;
				content: '';
				width: 100%;
				height: rem(260px);
				bottom: 0;
				left: 0;
				background: linear-gradient(to top, rgba(0,30,75,1) 0%,rgba(125,185,232,0) 100%);
				opacity: 0.06;
			}
		}

		.image.is-video {
			margin: 0 0 rem(-81px);
		}
	}

	&.is-speaker-block {
		text-align: center;

		.speaker {
			display: flex;
			flex-direction: column;
			height: 100%;
		}

		.speaker-title {
			@include font-size-16;
			font-weight: 600;
			margin: 0 0 rem(11px);
			color: $color-primary-light;
		}

		.container.is-larger {

			.image {
				margin: auto 0 0;
			}

			@include breakpoint("laptop-l") {

				.swiper-button-next {
					right: rem(46px);
					margin: 0;
				}

				.swiper-button-prev {
					left: rem(46px);
					margin: 0;
				}
			}
		}

		.speaker-slider {
			position: relative;
			padding: 0 rem(20px);
			margin: 0 0 rem(32px);

			@include breakpoint("tablet") {
				&.justify-center-1 .swiper-wrapper {
					display: flex;
					justify-content: center;
				}
			}

			@include breakpoint("laptop") {
				&.justify-center-2 .swiper-wrapper {
					display: flex;
					justify-content: center;
				}
			}


			@include breakpoint("laptop-l") {
				padding: 0 rem(126px);
			}
		}

		.speaker.is-modal {
			cursor: pointer;

			img {
				transition: transform $transition-time ease-in-out;
			}

			&:hover {

				img {
					transform: scale(1.2);
				}
			}
		}
	}

	&.has-text-centerd {
		text-align: center;
	}

	&.is-customer-block {

		.column {
			position: relative;

			&:nth-child(n+2) {
				display: none;
			}

			@include breakpoint("mobile-s") {

				&:nth-child(n+2) {
					display: block;
				}

				&:nth-child(n+3) {
					display: none;
				}
			}

			@include breakpoint("mobile-l") {

				&:nth-child(n+3) {
					display: block;
				}

				&:nth-child(n+4) {
					display: none;
				}
			}

			@include breakpoint("tablet") {

				&:nth-child(n+4) {
					display: block;
				}

				&:nth-child(n+5) {
					display: none;
				}
			}

			@include breakpoint("laptop") {

				&:nth-child(n+5) {
					display: block;
				}

				&:nth-child(n+6) {
					display: none;
				}
			}

			@include breakpoint("laptop-m") {

				&:nth-child(n+6) {
					display: block;
				}
			}
		}
	}

	&.is-sitemap-block {
		padding-top: 0;
	}

	.container {
		margin: 0 auto;

		.image {
			margin: 0 0 rem(48px);
		}

		.slider + .buttons {
			position: relative;
			z-index: 1;
			margin-top: rem(-48px);
		}
	}

	.title {
		margin: 0 0 rem(35px);

		@include breakpoint("tablet") {
			margin: 0 0 rem(45px);
		}

		&.is-bigger {
			margin: 0 0 rem(36px);

			+ .caption {
				margin: rem(-36px) 0 rem(36px);
			}

			@include breakpoint("tablet") {
				margin: 0 0 rem(46px);

				+ .caption {
					margin: rem(-46px) 0 rem(46px);
				}
			}
		}

		+ .image {
			margin-top: rem(45px);

			@include breakpoint("tablet") {
				margin-top: rem(55px);
			}
		}
	}

	.content {

		+ .button {
			margin-top: rem(21px);

			&:last-child {
				margin-bottom: rem(39px);
			}

			&.is-link:last-child {
				margin-bottom: rem(21px);
			}
		}

		+ .image {
			margin-top: rem(61px);
		}

		+ .list {
			margin-top: rem(51px);
			margin-bottom: rem(60px);
		}
	}

	.column.is-pulled-left {
		order: -1;
	}

	.column.is-pulled-left-l {

		@include breakpoint("laptop") {
			order: -1;
		}
	}

	.buttons {

		&:last-child {
			margin-bottom: rem(43px);
		}
	}

	.button + .image {
		margin-top: rem(30px);
	}

	.pagination-container + .button.is-link {
		margin-top: rem(43px);
	}
}
