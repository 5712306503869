﻿.products {

	.column {
		display: flex;
		align-items: flex-end;
		margin: 0 0 rem(20px);
	}

	.product {
		width: 100%;

		.product-logo {
			text-align: center;
		}

		.image {
			//background: #d0d0d0;
			margin: 0;
			overflow: hidden;

			img {
				transform: translateY(0);
				transition: transform $transition-time ease-in-out;
			}
		}

		&.is-external {

			.title {
				color: $color-webshop;

				&:before {
					position: relative;
					@include icon($icon-shopping);
					font-size: rem(18px);
					top: rem(-4px);
					right: rem(15px);
				}

				span:before {
					background: $color-webshop;
				}
			}
		}

		.title {
			@include font-text;
			position: relative;
			z-index: 1;
			color: $color-primary-light;
			text-align: center;
			text-transform: none;
			padding: 0 0 rem(13px);
			margin: 0;

			span {
				position: relative;

				&:before {
					position: absolute;
					content: '';
					width: 100%;
					height: 2px;
					bottom: rem(-3px);
					left: 0;
					background: $color-primary-light;
					transform: scaleX(0);
					transform-origin: right;
					transition: transform .4s cubic-bezier(.77, 0, .175, 1);
				}
			}
		}

		&:hover {

			.image {

				img {
					transform: translateY(-0.35rem);
				}
			}

			.title span:before {
				transform: scaleX(1);
				transform-origin: left;
			}
		}
	}
}
