﻿.brands-fader {
	padding: rem(84px) 0;

	.container {
		margin: 0 auto;
	}

	.column {
		position: relative;

		&:nth-child(n+2) {
			display: none;
		}

		@include breakpoint("mobile-s") {

			&:nth-child(n+2) {
				display: block;
			}

			&:nth-child(n+3) {
				display: none;
			}
		}

		@include breakpoint("mobile-l") {

			&:nth-child(n+3) {
				display: block;
			}

			&:nth-child(n+4) {
				display: none;
			}
		}

		@include breakpoint("tablet") {

			&:nth-child(n+4) {
				display: block;
			}

			&:nth-child(n+5) {
				display: none;
			}
		}

		@include breakpoint("laptop") {

			&:nth-child(n+5) {
				display: block;
			}

			&:nth-child(n+6) {
				display: none;
			}
		}

		@include breakpoint("laptop-m") {

			&:nth-child(n+6) {
				display: block;
			}
		}
	}
}
