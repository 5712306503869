﻿.container {
	width: 100%;
	max-width: rem(1192px);
	padding: 0 rem(20px);

	&.is-larger {
		max-width: rem(1432px);
	}

	&.is-large {
		max-width: rem(1680px);
	}

	&.is-medium {
		max-width: rem(800px);
	}
}
