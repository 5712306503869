﻿.usp {
	@include font-size-14;
	line-height: rem(24px);
	padding: rem(165px) rem(40px) 0;
	clip-path: polygon(0 0, 100% 0, 100% 100%, rem(24px) 100%, 0% calc(100% - 1.5rem)); // 24px
	background: #fff;

	.usp-title {
		@include font-size-20;
		font-weight: 800;
		text-align: center;
		text-transform: uppercase;
		margin: 0 0 rem(27px);
	}

	.content {
		margin: 0 0 rem(-41px);
	}

	&.is-customization,
	&.is-inspection-certification,
	&.is-maintenance-machines {
		position: relative;

		&:before {
			position: absolute;
			content: '';
			z-index: 1;
			top: rem(37px);
			left: 50%;
			transform: translateX(-50%);
		}
	}

	&.is-customization {

		&:before {
			width: rem(105px);
			height: rem(105px);
			background: url(../../images/usp/customization.svg) no-repeat;
			background-size: contain;
		}
	}

	&.is-inspection-certification {

		&:before {
			width: rem(80px);
			height: rem(110px);
			background: url(../../images/usp/inspection-certification.svg) no-repeat;
			background-size: contain;
		}
	}

	&.is-maintenance-machines {

		&:before {
			width: rem(102px);
			height: rem(102px);
			background: url(../../images/usp/maintenance-machines.svg) no-repeat;
			background-size: contain;
		}
	}
}
