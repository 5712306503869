﻿.input {
	width: 100%;
	min-height: rem(48px);
	padding: 0 rem(20px);
	overflow: hidden;
	border: 0;
	clip-path: polygon(0 0, 100% 0, 100% 100%, rem(12px) 100%, 0% calc(100% - 0.75rem)); // 12px
	color: $color-primary;
	transition: all $transition-time ease-in-out;
	line-height: rem(48px);

	&::placeholder {
		@include font-size-16;
		line-height: rem(48px);
		opacity: 1;
	}

	&:hover,
	&:focus {
		box-shadow: 0 0 5px #ff0000;
	}
}
