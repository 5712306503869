﻿.header-video {
	position: relative;
	width: 100vw;
	height: auto;
	margin-top: rem(100px);

	.has-vacancies & {
		margin-top: rem(180px);
	}

	@include breakpoint("laptop-l") {
		display: flex;
		align-items: center;
		height: calc(var(--hh) - 100px);

		.has-vacancies & {
			margin-top: rem(100px);
		}
	}

	.header-title {
		position: relative;
		z-index: 2;
		width: 100%;
		padding-top: rem(130px);
		margin: 0 0 rem(30px);

		@include breakpoint("laptop-l") {
			padding-top: 0;
		}

		.container {
			margin: 0 auto;
		}

		.title {
			@include font-size-72;
			color: $color-primary-light;

			strong {
				color: $color-primary;

				&:after {
					@include icon($icon-slash);
					position: relative;
					font-size: rem(36px);
					top: rem(-11px);
					left: rem(10px);
					color: $color-primary-light;

					@include breakpoint("tablet") {
						font-size: rem(43px);
						top: rem(-14px);
						left: rem(12px);
					}

					@include breakpoint("laptop") {
						font-size: rem(50px);
						top: rem(-16px);
						left: rem(14px);
					}
				}
			}

			margin: 0 0 rem(35px);
		}

		p {
			@include font-size-24;
			font-weight: 600;
			color: $color-primary-light;
		}
	}

	.header-item {
		position: relative;
		//width: 100vw;
		//min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
		height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
		max-height: 100vh;
		overflow: hidden;

		@include breakpoint("laptop-l") {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}
	}

	img,
	iframe,
	video {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;
	}

	img {
		z-index: 0;
	}

	iframe,
	video {
		z-index: 1;
		width: 100%;
		height: 100%;

		@include breakpoint("laptop-l") {
			width: 100vw;
			min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
			height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
			min-width: 180vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
			height: 57vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
			min-height: 100vh;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.skip {
		position: absolute;
		z-index: 2;
		bottom: rem(48px);
		left: 50%;
		animation: 2s infinite skip-pulse;
		display: none;

		@include breakpoint("tablet") {
			display: block;
		}

		.visuallyhidden {
			display: none;
		}

		&:after {
			@include icon($icon-down);
			font-size: rem(13px);
			color: #bbc3cd;
		}
	}

	@keyframes skip-pulse {

		0% {
			bottom: 1rem;
		}

		50% {
			bottom: 1.5rem;
		}

		100% {
			bottom: 1rem;
		}
	}
}
