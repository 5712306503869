﻿.languages-side-menu {
	@extend .dropdown-list;
	margin: rem(20px) auto;
	color: #fff;
	max-width: rem(360px);
	cursor: pointer;

	dt,
	dd {
		background: mix(#000, $color-primary, 15%);
	}

	dt {
		position: relative;
		padding: rem(15px) rem(32px);
		border-radius: $border-radius;

		&::after {
			@include icon($icon-arrow-up);
			position: absolute;
			top: 50%;
			right: rem(25px);
			transform: translateY(-50%);
			font-size: rem(13px);
			color: #fff;
		}
	}

	dd {
		position: relative;
		padding: 0.625rem 0;
		margin: 0 0 1.5rem;
		border-radius: $border-radius $border-radius 0 0;
		transform: translateY(calc(-100% - 3.5rem));
	}

	ul {
		padding: 0;
		margin: 0;
		list-style: none;
	}

	li:last-child a {
		border: none;
	}

	a {
		display: block;
		padding: 0.125rem 2rem;
		text-decoration: none;
		transition: transform $transition-time ease-in-out;
		border: none;

		&:hover,
		&:focus {
			//text-decoration: underline;
		}
	}

	&:not(.clicked):focus-within,
	&.clicked.open {

		dt,
		dt:focus {
			border-radius: 0 0 $border-radius $border-radius;

			&::after {
				content: $icon-arrow-down;
			}
		}

		dt,
		dd {
			//background: lighten($color-primary, 15%);
			background: $color-primary-hover;
		}
	}
}
