﻿.home-product-overview {
	padding: rem(52px) 0 0;
	background: rgba($color-primary, 0.06);
	border-top: 1px solid #fff;

	.container {
		padding-bottom: rem(33px);
		margin: 0 auto;
	}

	.home-product-overview-link {
		position: relative;
		display: block;
		padding: 0 0 0 rem(30px);
		margin: 0 0 rem(18px);

		span {
			position: relative;

			&:before {
				position: absolute;
				content: '';
				width: 100%;
				height: 1px;
				bottom: rem(-2px);
				left: 0;
				background: #000;
				transform: scaleX(0);
				transform-origin: right;
				transition: transform .4s cubic-bezier(.77, 0, .175, 1);
			}
		}

		&:before {
			@include icon($icon-right);
			position: absolute;
			width: rem(14px);
			height: rem(14px);
			padding: rem(4px) 0 0 rem(2px);
			border-radius: 50%;
			top: 50%;
			left: 0;
			color: #fff;
			transform: translateY(-50%);
			background: #0066db;
			font-size: rem(6px);
			text-align: center;
			transition: left $transition-time ease-in-out;
		}

		&:hover {

			span:before {
				transform: scaleX(1);
				transform-origin: left;
			}
		}
	}

	.home-product-overview-overview,
	.home-product-overview-webshop {
		display: block;
		width: 100%;
		height: 100%;
		padding: rem(31px) 0 rem(28px);
		text-align: center;
		font-weight: 600;
		border-top: 1px solid #fff;

		&:after {
			@include font-size-16;
			@include icon($icon-right);
			position: relative;
			display: inline-block;
			width: rem(18px);
			height: rem(18px);
			padding: rem(5px) 0 0 rem(2px);
			border-radius: 50%;
			top: rem(-3px);
			left: rem(17px);
			color: #fff;
			background: #0066db;
			font-size: rem(8px);
			text-align: center;
			transition: left $transition-time ease-in-out;
		}

		&:hover {

			&:after {
				left: rem(22px);
			}
		}
	}

	.home-product-overview-overview {
		color: $color-primary-light;
	}

	.home-product-overview-webshop {
		color: $color-webshop;
		border-left: 1px solid #fff;

		&:after {
			background: $color-webshop;
		}

		&:before {
			@include icon($icon-shopping);
			font-size: rem(18px);
			position: relative;
			display: inline-block;
			top: rem(-5px);
			left: rem(-15px);
		}
	}
}
