﻿.flipper {
	width: 25%;
	height: rem(64px);
	padding: 0 rem(10px);
	color: #fff;
	cursor: pointer;

	@include breakpoint("laptop") {
		width: rem(64px);
		top: rem(28px);
		clip-path: polygon(0 0, 100% 0, 100% 100%, rem(14px) 100%, 0% rem(50px));
	}

	&.is-telephone,
	&.is-webshop,
	&.is-search {
		text-indent: -9999px;
		transition: all $transition-time ease-in-out;

		&:before {
			position: absolute;
			content: '';
			top: 50%;
			left: 50%;
			text-indent: 0;
			font-size: rem(28px);
			transform: translate(-50%, -50%);
		}
	}

	&.is-search {
		background: $color-primary-light;

		&:before {
			@include icon($icon-search);
		}

		&:hover {
			background: darken($color-primary-light, 10%);
		}

		&:focus {
			background: darken($color-primary-light, 10%);
		}
	}

	&.is-telephone {
		background: $color-primary-lighter;

		&:before {
			@include icon($icon-phone);
		}

		&:hover {
			background: darken($color-primary-lighter, 10%);
		}

		&:focus {
			background: darken($color-primary-lighter, 10%);
		}

		@include breakpoint("laptop") {
			width: rem(264px);

			&:before {
				left: rem(32px);
			}

			&:after {
				position: absolute;
				content: attr(data-telephone);
				top: 50%;
				left: rem(74px);
				transform: translateY(-50%);
				text-indent: 0;
				font-weight: bold;
			}
		}
	}

	&.is-webshop {
		background: $color-webshop;

		&:before {
			@include icon($icon-shopping);
		}

		&:hover {
			background: darken($color-webshop, 7%);
		}

		&:focus {
			background: darken($color-webshop, 7%);
		}
	}
}
