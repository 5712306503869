﻿.title {
	@include font-size-40;
	font-weight: 800;
	text-transform: uppercase;

	&.is-centerd {
		text-align: center;
	}

	&.is-smaller {
		@include font-size-30;
	}

	&.is-bigger {
		@include font-size-54;
	}
}

.sub-title {
	font-weight: 600;
	margin: 0;
}
