﻿.button {
	@include font-size-16;
	position: relative;
	z-index: 0;
	display: inline-flex;
	border: none;
	padding: rem(15px) rem(60px) rem(14px) rem(40px);
	margin: 0;
	text-decoration: none;
	color: #fff;
	overflow: hidden;
	cursor: pointer;
	transition: background $transition-time ease-in-out;
	text-decoration: none;
	clip-path: polygon(0 0, 100% 0, 100% 100%, rem(12px) 100%, 0% calc(100% - 0.75rem)); // 12px
	background: $color-primary-light;
	font-weight: 600;
	min-height: rem(48px);

	&:before {
		background: #fff;
		content: "";
		height: 155px;
		left: -75px;
		opacity: .2;
		position: absolute;
		top: -50px;
		transform: rotate(-35deg);
		transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
		width: 50px;
		z-index: -10;
	}

	&:after {
		@include icon($icon-right);
		position: absolute;
		width: rem(18px);
		height: rem(18px);
		padding: rem(5px) 0 0 rem(2px);
		border-radius: 50%;
		top: 50%;
		right: rem(20px);
		transform: translateY(-50%);
		background: #0066db;
		font-size: rem(8px);
		text-align: center;
	}

	&.is-primary {
		background: $color-primary;

		&:after {
			background: #1a355d;
		}
	}

	&.is-full-width {
		display: inline-block;
		width: 100%;
		text-align: center;
	}

	&[href^="https://webshop.weterings"] {
		background: $color-webshop;
		padding-left: rem(70px);

		span:before {
			@include icon($icon-shopping);
			position: absolute;
			font-size: rem(24px);
			top: 50%;
			left: rem(30px);
			transform: translateY(-50%);
		}

		&:after {
			background: #009139;
		}
	}

	&[href^="#"] {

		&:after {
			@include icon($icon-down);
			padding: rem(6px) 0 0 0;
		}
	}

	&.is-link {
		padding-right: rem(31px);
		padding-left: 0;
		background: none;
		color: $color-primary-light;

		span {
			position: relative;

			&:before {
				position: absolute;
				content: '';
				width: 100%;
				height: 1px;
				bottom: rem(-2px);
				left: 0;
				background: $color-primary-light;
				transform: scaleX(0);
				transform-origin: right;
				transition: transform .4s cubic-bezier(.77, 0, .175, 1);
			}
		}

		&:after {
			@include icon($icon-down);
			width: rem(14px);
			height: rem(14px);
			padding: rem(5px) 0 0 0;
			right: rem(5px);
			color: #fff;
			font-size: rem(6px);
			transition: all $transition-time ease-in-out;
		}

		&[href^="#"] {

			&:after {
				padding: rem(5px) 0 0 0;
			}
		}

		&[href$=".pdf"],
		&[href^="https://webshop.weterings"] {
			color: $color-webshop;
			background: none;

			&:after {
				@include icon($icon-right);
				padding: rem(4px) 0 0 rem(1px);
				background: $color-webshop;
			}

			&:before {
				display: none;
			}

			span:before {
				background: $color-webshop;
				top: auto;
			}

			&:hover,
			&:focus {
				background: none;

				&:after {
					margin: 0;
					right: 0;
				}
			}
		}
	}

	&.is-loading {
		padding-left: rem(50px);

		&:after {
			position: absolute;
			display: block;
			content: '';
			border: rem(4px) solid rgba(#fff, 0.5);
			border-radius: 50%;
			border-top: rem(4px) solid #fff;
			width: rem(20px);
			height: rem(20px);
			top: rem(14px);
			left: rem(16px);
			animation: spin 1.5s linear infinite;
		}
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}

	&:focus {
		@extend .button-focus;
	}

	&:hover {

		@extend .button-hover;
	}
}

.button-focus {
	background: darken($color-primary-light, 10%);

	&.is-link {
		background: none;

		&:after {
			margin-top: rem(2.5px);
		}

		&[href$=".pdf"] {

			&:after {
				margin-top: 0;
				right: rem(1px);
			}
		}

		span:before {
			transform: scaleX(1);
			transform-origin: left;
		}
	}

	&.is-primary {
		background: darken($color-primary, 10%);
	}

	&[href^="https://webshop.weterings"] {
		background: darken($color-webshop, 10%);
	}
}

.button-hover {

	@media (hover: hover) {

		background: darken($color-primary-light, 10%);

		&:before {
			left: 120%;
			transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
		}

		&.is-link {
			background: none;

			&:after {
				margin-top: rem(5px);
			}

			&[href$=".pdf"] {

				&:after {
					margin-top: 0;
					right: rem(1px);
				}
			}

			span:before {
				transform: scaleX(1);
				transform-origin: left;
			}
		}

		&.is-primary {
			background: darken($color-primary, 10%);
		}

		&[href^="https://webshop.weterings"] {
			background: darken($color-webshop, 10%);
		}
	}
}

button.button {
	padding-top: rem(16px);
}

.buttons {
	margin-right: rem(-10px);
	margin-left: rem(-10px);

	.button {
		margin: rem(10px) rem(10px);

		&:last-child {
			margin-bottom: rem(10px);
		}
	}

	&.is-full-width {
		display: flex;
		flex-direction: column;

		.button {
			display: inline-block;
			text-align: center;
		}
	}

	&.has-links {
		margin: 0 rem(-20px);

		.button {
			margin: 0 rem(20px);
		}
	}

	&.is-centerd {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
}
