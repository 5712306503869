﻿.legal {
	@include font-size-12;
	display: flex;
	flex-wrap: wrap;
	padding: rem(14px) 0 rem(19px);
	//margin: rem(10px) 0 0;
	list-style: none;
	font-style: initial;
	color: $color-primary-light;

	li {
		margin: 0 rem(17px) 0 0;
	}

	a {

		&:hover {
			text-decoration: underline;
		}
	}
}
