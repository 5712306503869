﻿.nav.is-header-nav {
	@include font-size-16;
	font-weight: 500;
	display: none;
	float: right;
	color: #fff;
	width: calc(100% - 265px);


	@include breakpoint("laptop") {
		display: block;
	}

	.nav-container {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex-wrap: wrap;
		list-style: none;
		padding: 0;
	}

	.nav-item {

		label,
		input {
			display: none;
		}

		&.is-active,
		&:hover {

			> a {
				z-index: 2;

				> span:before {
					transform: scaleX(1);
					transform-origin: left;
				}
			}
		}

		&:hover {

			ul {
				display: block;
			}
		}

		&.has-children:hover {

			> a {
				background: $color-primary-hover;
			}
		}

		&.is-webshop {
			position: relative;
			color: $color-webshop;

			a {
				padding-left: rem(53px);

				&:before {
					@include icon($icon-shopping);
					position: absolute;
					font-size: rem(18px);
					top: rem(11px);
					left: rem(26px);
				}

				span:before {
					background: $color-webshop;
				}
			}
		}
	}

	.search-toggle {
		font-size: rem(15px);
		padding: rem(9px) rem(22px) rem(14px);
		cursor: pointer;

		&:hover,
		&:focus {
			background: mix($color-primary-hover, $color-primary-light, 85%);
		}
	}

	a {
		position: relative;
		display: block;
		color: inherit;
		text-decoration: none;
		padding: rem(12px) rem(20px) rem(11px);
		white-space: nowrap;
	}

	span {
		position: relative;

		&:before {
			position: absolute;
			content: '';
			width: 100%;
			height: 1px;
			bottom: rem(-2px);
			left: 0;
			background: #fff;
			transform: scaleX(0);
			transform-origin: right;
			transition: transform .4s cubic-bezier(.77, 0, .175, 1);
		}
	}

	.nav-item {
		position: relative;

		ul {
			position: absolute;
			background: $color-primary-hover;
			padding: 0;
			min-width: 100%;
			list-style: none;
			clip-path: polygon(0 0, 100% 0, 100% 100%, rem(24px) 100%, 0% calc(100% - 1.5rem)); // 24px
			display: none;

			.nav-item {

				a {
					padding: rem(12px) rem(40px) rem(11px);

					&:hover {

						span:before {
							transform: scaleX(1);
							transform-origin: left;
						}
					}

					border-bottom: 1px solid rgba(#fff, 0.2);

					&[href^="https://webshop.weterings"] {
						color: $color-webshop;

						span {
							position: relative;
							padding-left: rem(40px);
						}

						span:after {
							@include icon($icon-shopping);
							position: absolute;
							font-size: rem(24px);
							top: 50%;
							left: 0;
							transform: translateY(-50%);
						}

						span:before {
							background: $color-webshop;
						}
					}
				}

				&:first-child a {
					padding-top: rem(22px);
				}

				&:last-child a {
					padding-bottom: rem(14px);
				}
			}
		}
	}

	.languages {
		@extend .languages-header-menu;
	}
}
