﻿.category-slider {
	position: relative;
	padding: 0 rem(20px) 0;

	@include breakpoint("tablet") {
		padding: 0 rem(140px) 0;
	}

	.swiper-button-prev {
		left: rem(20px);

		@include breakpoint("tablet") {
			left: rem(50px);
		}
	}

	.swiper-button-next {
		right: rem(20px);

		@include breakpoint("tablet") {
			right: rem(50px);
		}
	}

	.swiper-slide {
		display: flex;
		height: auto;
		align-items: flex-end;

		@include breakpoint("tablet") {
			border-left: 1px solid #fff;
		}
	}

	.swiper-container {

		@include breakpoint("tablet") {
			border-right: 0.5px solid #fff;
		}
	}

	.product {
		padding: rem(80px) 0 rem(15px);
		background: #fff;

		@include breakpoint("tablet") {
			border-left: 0.5px solid #fff;
		}

		&:before {
			position: absolute;
			z-index: 1;
			content: '';
			width: 100%;
			height: rem(410px);
			bottom: 0;
			left: 0;
			background: linear-gradient(to bottom, rgba(0,30,75,0) 0%,rgba(0,30,75,1) 100%);
			opacity: 0.06;
		}

		.title {
			position: relative;
			z-index: 2;
		}
	}


	&:before {
		position: absolute;
		z-index: -1;
		content: '';
		width: 100%;
		height: rem(410px);
		bottom: 0;
		left: 0;
		background: linear-gradient(to bottom, rgba(0,30,75,0) 0%,rgba(0,30,75,1) 100%);
		opacity: 0.06;
	}
}
