﻿.languages-header-menu {
	position: relative;
	max-width: rem(600px);
	margin: rem(50px) auto;

	@include breakpoint("laptop") {
		margin: 0;
	}

	dl {
		position: relative;
		overflow: hidden;
		width: rem(72px);
		height: rem(50px);
		color: #fff;
	}

	dt {
		display: block;
		position: relative;
		text-indent: -99999px;
		width: rem(42px);
		height: rem(42px);

		&:after {
			@include icon($icon-globe);
			position: absolute;
			font-size: rem(20px);
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-indent: 0;
		}
	}

	&:hover {
		background: mix($color-primary-hover, $color-primary-light, 85%);

		dt a {
			background: $color-primary;
		}

		dd {
			display: block;
		}
	}

	dd {
		background: mix($color-primary-hover, $color-primary-light, 85%);
		clip-path: polygon(0 0, 100% 0, 100% 100%, rem(24px) 100%, 0% calc(100% - 1.5rem)); // 24px
		margin: 0;

		@include breakpoint("laptop") {
			position: absolute;
			display: none;
			top: 100%;
			right: 0;
		}

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
		}

		li {

			&:last-child a {
				border-bottom: 1px solid rgba(#fff, 0.2);
			}

			@include breakpoint("laptop") {

				&:first-child a {
					padding-top: rem(14px);
				}

				&:last-child a {
					padding-bottom: rem(14px);
					border: none;
				}
			}
		}

		a {
			padding: rem(12px) rem(24px) rem(11px) rem(34px);
			color: #fff;
			display: block;
			text-decoration: none;
			border-bottom: 1px solid rgba(#fff, 0.2);

			&:hover {
				text-decoration: underline;
			}
		}
	}
}
