﻿.modal-overlay {
	position: fixed;
	z-index: 100;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	overflow: hidden;
	background: rgba(0, 0, 0, 0.5);
	display: none;

	&.is-visible {
		display: block;
	}
}

.modal {
	position: relative;
	overflow: auto;
	width: 100%;
	max-width: rem(600px);
	max-height: 100vh;
	display: inline-block;
	vertical-align: middle;
	margin: 0 auto;
	top: 50%;
	transform: translateY(-50%);
}

.modal-content {
	background: #fff;
	clip-path: polygon(0 0, 100% 0, 100% 100%, rem(24px) 100%, 0% calc(100% - 1.5rem)); // 24px
}

.modal-content-container {
	padding: rem(29px) rem(30px) rem(31px)
}

.modal-close {
	position: absolute;
	content: '';
	width: rem(36px);
	height: rem(36px);
	top: rem(10px);
	right: rem(10px);
	background: none;
	border: none;
	overflow: hidden;
	text-indent: -9999px;
	cursor: pointer;
	color: #fff;
	text-align: center;
	border-radius: 50%;

	&:after {
		@include icon($icon-close);
		position: absolute;
		top: rem(10px);
		left: rem(10px);
		font-size: rem(16px);
		text-indent: 0;
	}

	&:hover {
		background: mix($color-primary-light, $color-primary, 25%);
	}

	&:focus {
		background: mix($color-primary, $color-primary-light, 15%);
	}
}