﻿.content {

	&.is-centerd {
		text-align: center;
	}

	p,
	ul,
	ol {
		margin: 0 0 rem(30px);
	}

	a {
		color: inherit;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	table {
		margin: 0 0 rem(25px);

		td {
			display: block;

			p {
				margin: 0;
			}

			@include breakpoint("tablet-s") {
				display: table-cell;
				padding-right: rem(20px);
			}

			@include breakpoint("laptop") {
			}
		}

		tr {
			display: block;
			margin: 0 0 rem(15px);

			@include breakpoint("tablet-s") {
				display: table-row;
				margin: 0;
			}
		}
	}

	ol:not(.social-media),
	ul:not(.social-media) {
		list-style: none;
		padding: 0;
		overflow: hidden;

		li {
			position: relative;
			padding: rem(9px) 0 rem(10px) rem(50px);
			border-top: 1px solid rgba(#000, 0.2);

			&:after {
				position: absolute;
				content: '';
				width: rem(7px);
				height: rem(7px);
				border-radius: 50%;
				top: rem(22px);
				left: rem(22px);
				background: $color-text;
			}

			&:last-child {
				border-bottom: 1px solid rgba(#000, 0.2);
			}
		}
	}
}
