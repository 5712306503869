﻿.vacancies-button {
	@include font-size-16;
	width: 100%;
	padding: rem(23px) rem(20px) rem(22px);
	background: #0078ff;
	color: #fff;
	font-weight: bold;
	text-align: center;
	transition: all $transition-time ease-in-out;

	&:hover,
	&:focus {
		background: darken($color-primary-light, 10%);
	}

	@include breakpoint("tablet") {
		width: initial;
		clip-path: polygon(0 0, 100% 0, 100% 100%, rem(14px) 100%, 0% rem(50px));
		text-align: initial;
	}
}
