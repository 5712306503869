﻿.call-to-action {
	position: relative;
	z-index: 0;
	display: block;
	overflow: hidden;
	background: $color-background url(../../images/patterns/pattern-grey.svg) no-repeat right top;
	background-size: rem(1280px) rem(2880px);
	padding: rem(34px) 0 rem(35px);
	transition: background $transition-time ease-in-out;

	&.has-image {
		display: flex;
		align-items: center;
		height: rem(500px);

		@include breakpoint("laptop") {
			height: rem(600px);
		}

		@include breakpoint("laptop-m") {
			height: rem(700px);
		}

		@include breakpoint("laptop-l") {
			height: rem(800px);
		}

		.title {
			@include font-size-54;
			color: $color-primary-light;

			strong {
				color: #fff;

				&:after {
					@include icon($icon-slash);
					position: relative;
					font-size: rem(38px);
					top: rem(-12px);
					left: rem(12px);
					color: $color-primary-light;
				}
			}
		}

		&:before {
			position: absolute;
			z-index: -1;
			content: '';
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: linear-gradient(to right, rgba(0,30,75,1) 0%,rgba(0,30,75,0) 100%);
		}
	}

	.container {
		margin: 0 auto;
	}

	.columns {
		align-items: center;
	}

	.title {
		@include font-size-30;
		font-weight: 800;
		text-transform: uppercase;
		color: $color-primary;

		strong {
			color: $color-primary-light;
		}

		+ .button {
			margin-top: rem(45px);
		}
	}


	.call-to-action-link-container {
		padding-top: rem(20px);
		padding-bottom: rem(20px);

		@include breakpoint("laptop") {
			display: flex;
			justify-content: flex-end;
		}
	}

	.call-to-action-image {
		position: absolute;
		z-index: -2;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;
	}

	&.is-small {
		//background: $color-background url(../../images/patterns/pattern-grey.svg) no-repeat right top;
		height: 100%;
		padding: 0;
		background-position: left -200px top;

		.call-to-action-text-container {
			display: flex;
			flex-direction: column;
			height: 100%;
			padding: rem(36px) rem(20px) rem(36px);

			.title {
				margin: 0 0 rem(36px);
			}

			@include breakpoint("tablet") {
				padding: rem(36px) rem(40px);
			}

			.button {
				min-height: initial;

				&:last-child {
					margin: auto 0 0;
				}
			}
		}
	}
}
