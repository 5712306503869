﻿$breakpoints:
	"mobile-s" "ms" 320px,
	"mobile-m" "mm" 375px,
	"mobile-l" "ml" 432px,
	"tablet-s" "ts" 620px,
	"tablet" "t" 768px,
	"laptop" "l" 1024px,
	"laptop-m" "lm" 1280px,
	"laptop-l" "ll" 1440px,
	"desktop-s" "ds" 1680px,
	"desktop" "d" 1920px,
	"4k" "4k" 2560px;

@mixin breakpoint($breakpoint-name) {

	@each $name, $name-short, $pixels in $breakpoints {

		@if $breakpoint-name == $name {

			@media only screen and (min-width: $pixels) {
				@content;
			}
		}
	}
}