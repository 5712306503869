﻿.album {

	.album-item {
		transition: all $transition-time ease-in-out;
		display: flex;
		flex-direction: column;

		&.is-hidden {
			visibility: hidden;
			opacity: 0;
		}
	}

	.image {
		margin-top: auto !important;

		img {
			transition: transform $transition-time ease-in-out; 
			transform: scale(1);
		}

		a:hover img {
			transform: scale(1.2);
		}
	}
}
