﻿.custome-made-banner {
	position: relative;
	display: block;
	overflow: hidden;
	background: $color-primary-light;
	padding: rem(54px) 0 rem(205px);
	transition: background $transition-time ease-in-out;

	@include breakpoint("laptop") {
		padding: rem(54px) 0 rem(55px);
	}

	.home & .container {
		max-width: rem(1696px);
	}

	&:before {
		position: absolute;
		content: '';
		width: rem(1100px);
		height: rem(150px);
		bottom: rem(20px);
		left: rem(45px);
		background: url(../../images//measuring-tape.png);
		background-size: contain;
		transition: all $transition-time ease-in-out;

		@include breakpoint("laptop") {
			top: 50%;
			right: rem(-745px);
			bottom: auto;
			left: auto;
			transform: translateY(-50%) scale(1);
		}

		@include breakpoint("laptop-m") {
			right: rem(-645px);
		}

		@include breakpoint("laptop-l") {
			right: rem(-445px);
		}

		@include breakpoint("desktop-s") {
			right: rem(-245px);
		}
	}

	&:hover {
		background: mix($color-primary-lighter, $color-primary-light, 25%);

		&:before {
			left: rem(5px);

			@include breakpoint("laptop") {
				transform: translateY(-50%);
				right: rem(-705px);
				left: auto;
			}

			@include breakpoint("laptop-m") {
				right: rem(-505px);
			}

			@include breakpoint("laptop-l") {
				right: rem(-305px);
			}

			@include breakpoint("desktop-s") {
				right: rem(-205px);
			}
		}
	}

	&.is-small {
		height: 100%;
		padding: rem(35px) rem(20px) rem(174px);

		&:before {
			width: rem(1000px);
			height: rem(136px);
			top: auto;
			right: auto;
			bottom: rem(-2px);
			left: rem(30px);
			transform: none;
		}

		&:hover {

			&:before {
				left: 0;
				transform: none;
			}
		}
	}

	.container {
		margin: 0 auto;
	}

	.custome-made-banner-text {
		@include font-size-30;
		font-weight: 800;
		text-transform: uppercase;
		color: #fff;

		strong {
			color: $color-primary-lighter;
		}
	}
}
