﻿.checkbox,
.dataconsent {
	position: relative;
	text-align: left;

	input {
		position: absolute;
		margin: 0;
		top: 0;
		left: 0;
		opacity: 0;

		&:checked + label {

			&:before {
				color: $color-primary;
			}
		}

		&:focus + label:before {
			border-color: $color-primary;
		}
	}

	label {
		position: relative;
		display: block;
		padding: rem(4px) 0 0 rem(36px);
		margin: 0;
		cursor: pointer;
		margin: rem(24px) 0 rem(0);
		font-size: rem(16px);

		&:before {
			@include icon($icon-check);
			position: absolute;
			width: rem(20px);
			height: rem(20px);
			//border: 1px solid #ababab;
			padding: rem(4px) 0 0;
			top: rem(9px);
			left: 0;
			text-align: center;
			background: #fff;
			font-size: 13px;
			color: transparent;
		}

		&:focus,
		&:hover {

			&:before {
				border-color: $color-primary;
			}
		}

		p {
			margin: 0;
		}

		a {
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}
}
