﻿.vacancies {
	padding: rem(79px) 0 rem(51px);

	@include breakpoint("tablet") {
		padding: rem(109px) 0 rem(81px);
	}

	.breadcrumb {
		margin: -3.5625rem 0 3.1875rem;

		@include breakpoint("tablet") {
			margin: -5.0625rem 0 5.0625rem;
		}
	}

	.container {
		margin: 0 auto;

		> .title {
			margin: 0 0 2.875rem;
		}

		> .content {
			margin: 0 0 rem(64px);
		}
	}

	+ .has-white-background {

		&:before {
			position: absolute;
			content: '';
			width: 100%;
			height: 1px;
			max-width: rem(1152px);
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			background: rgba($color-primary-light, 0.2);
		}
	}
}

.vacancy {
	display: block;
	background: #f0f2f4;
	margin: 0 0 rem(48px);

	.image {

		&.has-placeholder {
			background: rgba(#000, 0.1) url(../../images/logo-weterings-machinery-no-text.svg) no-repeat center;
			background-size: rem(128px) rem(128px);
		}
	}

	.same-height {
		overflow: hidden;
	}

	.vacancy__content {
		padding: rem(20px);

		@include breakpoint("tablet") {
			padding: rem(32px);
		}
	}

	.title {
		@include font-size-20;
		margin: 0 0 rem(16px);
	}

	.content {

		@include breakpoint("tablet") {
			margin: 0;
		}
	}
}