﻿.pagination {
	position: relative;
	z-index: 0;
	list-style: none;
	margin: 0 rem(-24px);
	padding: 0;
	display: flex;
	justify-content: center;

	a {
		//
	}

	.page-link {
		@extend .button;

		&:after {
			@include icon($icon-down);
			padding-left: 1px;
		}
	}

	.pagination-item {
		padding: rem(10px);
	}
}
