﻿.textarea {
	width: 100%;
	min-height: rem(328px);
	padding: rem(10px) rem(28px);
	border: 1px solid #fff;
	-moz-appearance: none;
	-webkit-appearance: none;
	resize: vertical;
	clip-path: polygon(0 0, 100% 0, 100% 100%, rem(12px) 100%, 0% calc(100% - 0.75rem)); // 12px

	&::placeholder {
		@include font-size-16;
		opacity: 1;
	}

	&:focus {
		border-color: lighten($color-primary, 25%);
	}
}
