﻿.breadcrumb {
	@include font-size-13;

	ul {
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		padding: 0;
		margin: 0 0 rem(-10px);
	}

	li {
		position: relative;
		font-weight: 600;
		opacity: 0.5;

		&.is-active {
			font-weight: normal;
		}

		&:hover {
			text-decoration: underline;
		}

		&:not(:last-child) {
			padding: 0 rem(12px) 0 0;
			margin: 0 rem(7px) rem(10px) 0;

			&:after {
				@include icon($icon-arrow-right);
				position: absolute;
				top: 50%;
				right: 0;
				font-size: rem(7px);
				transform: translateY(-50%);
			}
		}
	}
}
