﻿@import "~lightgallery.js/dist/css/lightgallery.css";

.lg-backdrop {
	background: $color-primary;
}

.lg-backdrop.in {
	opacity: 0.9;
}

.lg-outer {
	//padding: rem(50px);
}

.lg-toolbar {
	background: none;

	#lg-counter {
		color: #fff;
	}

	.lg-close {
		background: $color-primary-lighter;
		width: rem(64px);
		height: rem(64px);
		padding: 0 rem(10px);
		clip-path: polygon(0 0, 100% 0, 100% 100%, rem(14px) 100%, 0% rem(50px));

		&:after {
			color: #fff;
		}

		&:hover {
			background: darken($color-primary-lighter, 10%);
		}
	}
}
