﻿.list {

	.column {

		&:first-child {

			.list-item {
				border-top: solid 1px rgba($color-primary-light, 0.2);
			}

			+ .column .list-item {

				@include breakpoint("laptop") {
					border-top: solid 1px rgba($color-primary-light, 0.2);
				}
			}
		}
	}

	.list-item {
		position: relative;
		border-bottom: solid 1px rgba($color-primary-light, 0.2);
		padding: rem(14px) rem(20px) rem(15px) rem(56px);

		&:before {
			@include icon($icon-check);
			position: absolute;
			top: rem(20px);
			left: rem(20px);
			font-size: rem(16px);
			color: $color-primary-light;
		}
	}
}
