﻿#search {
	display: none;

	&:checked + .search-wrapper {
		visibility: visible;
		opacity: 1;
	}
}

.search-wrapper {
	position: fixed;
	z-index: 1000;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(#000, 0.8);
	visibility: hidden;
	opacity: 0;

	.container {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	form {
		width: 100%;
	}

	.search__container {
		display: flex;
	}

	.search__extra {
		text-align: center;

		div {
			color: #fff;
			font-weight: 600;
			padding: rem(12px) 0;
		}
	}

	.visuallyhidden {
		display: none;
	}

	.input {
		height: rem(64px);
		line-height: rem(64px);
		font-size: rem(20px);

		&::placeholder {
			font-size: rem(20px);
			line-height: rem(64px);
			opacity: 1;
		}
	}

	button {
		background: $color-primary-light;
		border: none;
		width: rem(64px);
		padding: 0 0 rem(4px);
		color: #fff;
		cursor: pointer;

		&:hover,
		&:focus {
			background: mix($color-primary-light, $color-primary, 25%);
		}
	}
}
