﻿.image {

	&.is-square {
		@include responsive-container-psudo(1, 1);
	}

	&.is-3by2 {
		@include responsive-container-psudo(3, 2);
	}

	&.is-4by3 {
		@include responsive-container-psudo(4, 3);
	}

	&.is-big-image {
		@include responsive-container-psudo(1150, 648);
	}

	&.is-bottom {
		@include responsive-container-psudo(1392, 618);
	}

	&.is-person {
		max-width: rem(600px);
	}

	&.has-placeholder {

		&:after {
			position: absolute;
			z-index: -1;
			content: '';
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-size: cover;
			//transition: background $transition-time ease-in-out;
			//background: $color-primary url(../../images/dotted-line-no-transparency.svg) 70px center no-repeat;
			background-size: rem(540px) rem(540px);
		}

		&.hover:after {
			background-color: rgba($color-primary, 0.9);
		}
	}

	&.is-video {
		position: relative;
		overflow: hidden;

		a[rel=lightbox] {
			&:after {
				@include icon($icon-right);
				position: absolute;
				width: rem(100px);
				height: rem(100px);
				border-radius: 50%;
				padding: rem(29px) 0 0 rem(5px);
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				font-size: rem(38px);
				background: #fff;
				color: $color-primary-light;
				text-align: center;
				transition: all $transition-time ease-in-out;
				box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 1);
			}

			&:focus {

				&:after {
					background: mix(#fff, $color-primary-light, 85%);
					box-shadow: 0px 0px 75px 0px rgba(0, 0, 0, 1);
				}
			}

			&:hover {

				&:after {
					background: mix(#fff, $color-primary-light, 75%);
					box-shadow: 0px 10px 80px 0px rgba(0, 0, 0, 1);
				}
			}
		}

		&.is-small a[rel=lightbox]:after {
			width: rem(60px);
			height: rem(60px);
			font-size: rem(26px);
			padding: rem(17px) 0 0 rem(5px);
		}
	}

	&.has-hover {
		overflow: hidden;

		img {
			transform: scale(1);
			transition: transform $transition-time ease-in-out;
		}

		&:hover {

			img {
				transform: scale(1.2);
			}
		}
	}

	&.is-faded-image {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		animation: image-fade-in 1s ease-in-out backwards;
	}

	img {
		width: 100%;
		height: auto;
	}
}

.image-title {
	@include font-size-16;
	font-weight: 600;
	color: $color-primary-light;
}

@keyframes image-fade-in {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}