@font-face {
	font-family: 'Montserrat';
	src: url('../../fonts/Montserrat-Bold.woff2') format('woff2'), url('../../fonts/Montserrat-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../../fonts/Montserrat-ExtraBold.woff2') format('woff2'), url('../../fonts/Montserrat-ExtraBold.woff') format('woff');
	font-weight: 800;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../../fonts/Montserrat-Medium.woff2') format('woff2'), url('../../fonts/Montserrat-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../../fonts/Montserrat-Regular.woff2') format('woff2'), url('../../fonts/Montserrat-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../../fonts/Montserrat-SemiBold.woff2') format('woff2'), url('../../fonts/Montserrat-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@mixin font-text {
	font-family: $font-text;
	font-size: rem(19px);
	line-height: rem(30px);

	@media only screen and (min-width: 432px) {
		font-size: rem(18px);
	}

	@media only screen and (min-width: 600px) {
		font-size: rem(17px);
	}
}

@mixin font-size-12 {
	font-size: rem(12px);
	line-height: 1.2em;
}

@mixin font-size-13 {
	font-size: rem(13px);
	line-height: 1.2em;
}

@mixin font-size-14 {
	font-size: rem(14px);
	line-height: rem(30px);
}

@mixin font-size-16 {
	font-size: rem(16px);
	line-height: rem(19px);
}

@mixin font-size-17 {
	font-size: rem(17px);
	line-height: rem(30px);
}

@mixin font-size-20 {
	font-size: rem(20px);
	line-height: rem(20px);
}

@mixin font-size-24 {
	font-size: rem(24px);
	line-height: rem(30px);
}

@mixin font-size-30 {
	font-size: rem(30px);
	line-height: rem(30px);
}

@mixin font-size-40 {
	font-size: rem(30px);
	line-height: rem(30px);

	@include breakpoint("tablet") {
		font-size: rem(40px);
		line-height: rem(40px);
	}
}

@mixin font-size-54 {
	font-size: rem(34px);
	line-height: rem(30px);

	@include breakpoint("tablet") {
		font-size: rem(44px);
		line-height: rem(40px);
	}

	@include breakpoint("laptop") {
		font-size: rem(54px);
		line-height: rem(50px);
	}
}

@mixin font-size-72 {
	font-size: rem(52px);
	line-height: rem(52px);

	@include breakpoint("tablet") {
		font-size: rem(62px);
		line-height: rem(62px);
	}

	@include breakpoint("laptop") {
		font-size: rem(72px);
		line-height: rem(72px);
	}
}