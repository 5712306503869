﻿.social-media {
	list-style: none;
	font-size: rem(20px);
	line-height: 1;
	width: 100%;
	overflow: hidden;
	margin: 0;
	padding: 0;
	color: $color-primary-light;

	.bottom-bar &,
	main & {
		font-size: 1.5rem;
	}

	@include breakpoint("tablet") {
		width: auto;
		float: left;
		margin: -0.5rem 0 0.5rem !important;
	}

	li {
		display: inline-block;
		margin: 0.5rem;
		width: auto;
	}

	a {
		display: block;
		text-decoration: none;
		padding: 0 0.125rem;
		transition: color 0.25s ease-in-out;

		&:focus,
		&:hover {
			text-decoration: none;
			color: $color-primary;
			outline: none;
		}
	}

	.visuallyhidden {
		display: none;
	}
}

.social-media-title {
	@include font-size-17;
}
