﻿$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-layer-width: 32px;
$hamburger-layer-height: 3px;
$hamburger-layer-spacing: 7px;
$hamburger-layer-color: #fff;
$hamburger-layer-border-radius: 0;
$hamburger-hover-opacity: 1;
$hamburger-hover-transition-duration: 0.3s;
$hamburger-hover-transition-timing-function: ease-in-out;

@import "~hamburgers/_sass/hamburgers/hamburgers";

.hamburger {
	z-index: 1001;
	border: none;
	transition: opacity $transition-time ease-in-out, background $transition-time ease-in-out, color $transition-time ease-in-out, right $transition-time ease-in-out;
	background: $color-primary;

	.hamburger-box {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.hamburger-inner,
	.hamburger-inner:before,
	.hamburger-inner:after {
		transition: all 0.3s ease-in-out;
	}

	&.hamburger--spin.is-active .hamburger-inner::after {
		transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), background 0.3s ease-in-out;
	}

	&:hover {
		background: lighten($color-primary, 15%);
	}

	&:focus {
		background: lighten($color-primary, 10%);
	}
}
