﻿.h1 + .search-results {
	margin-top: rem(32px);
}

.search-results {
	list-style: none;
	padding: 0;

	li {
		padding: 0 0 rem(40px);
		border-bottom: 1px solid $color-primary;
		margin: 0 0 rem(41px);

		&:last-child {
			border-bottom: none;
		}

		@include breakpoint("tablet-s") {
			display: flex;
		}

		.image {
			max-width: rem(200px);
			width: 100%;
			flex-shrink: 0;
			margin: 0 !important;

			@include breakpoint("tablet-s") {
				margin-right: rem(32px) !important;
			}
		}
	}

	a {
		font-weight: 800;
		text-transform: uppercase;
	}

	.search-results__highlighted {
		padding: rem(40px);
		background: $color-primary-light;
		border-bottom: none;
		display: block;

		@include breakpoint("laptop") {
			display: flex;
			align-items: flex-start;
			padding: rem(40px) rem(40px) 0 rem(40px);
		}

		.title {
			@include font-size-30;
			color: #fff;

			@include breakpoint("laptop") {
				padding: 0 rem(96px) 0 0 0;
			}

			strong {
				color: $color-primary-lighter;
			}
		}

		.button {
			flex-shrink: 0;
		}
	}

	.search-results__content {
	}
}
