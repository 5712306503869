﻿@import "~swiper/dist/css/swiper.css";

.slider {
	position: relative;	
	//padding: rem(30px) 0 0;
}

.swiper-custom-pagination {
	width: 100%;
	text-align: center;
	margin: 0 0 rem(27px);

	.swiper-pagination-bullet {
		@include font-size-16;
		font-weight: 600;
		width: auto;
		height: auto;
		background: none;
		color: $color-primary-light;
		padding: 0 rem(19px);
		opacity: 0.5;
		transition: opacity $transition-time ease-in-out;

		&:focus {
			opacity: 0.75;
		}

		&:hover {
			opacity: 0.85;
		}

		&-active {
			opacity: 1;
		}
	}
}

.swiper-button-next,
.swiper-button-prev {
	width: rem(40px);
	height: rem(40px);
	margin-top: 0;
	border-radius: 50%;
	background: #b7bdc6;
	transition: all $transition-time ease-in-out;

	&:focus {
		background: lighten(#b7bdc6, 10%);
	}

	&:hover {
		background: lighten(#b7bdc6, 15%);
	}

	&:before {
		position: absolute;
		content: '';
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: rem(17px);
		color: #fff;
	}

	&.swiper-button-disabled {
		display: none;
	}
}

.swiper-button-next {
	right: 0;

	&:before {
		@include icon($icon-right);
		margin-left: 1px;
	}
}

.swiper-button-prev {
	left: 0;

	&:before {
		@include icon($icon-left);
		margin-left: -1px;
	}
}

.highlighted-slider {
	position: relative;
	width: 100%;
	max-width: rem(1350px);
	padding: 0;
	margin: 0 auto;

	@include breakpoint("laptop-m") {
		padding: 0 rem(75px);
	}

	.highlighted-slider-title {
		@include font-size-16;
		color: $color-primary-light;
		text-align: center;
		margin: 0 0 rem(11px);
	}

	.swiper-button-next {
		right: rem(10px);

		@include breakpoint("laptop-m") {
			right: 0;
		}
	}

	.swiper-button-prev {
		left: rem(10px);

		@include breakpoint("laptop-m") {
			left: 0;
		}
	}
}
.swiper-container {

	.swiper-slide {
		height: auto;
	}
}

.highlighted-slider-item {
	margin: 0 0 rem(41px);
	height: 100%;

	.usp {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: center;
		height: 100%;

		.button:last-child {
			align-items: flex-end;
			margin-bottom: rem(19px);
		}
	}

	.swiper-container & {
		padding: 0 rem(24px);
	}
}